.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.template {
  padding-top: 6rem;

  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 3rem;
}
.user-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.upgrade_actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
}