.side-bar-container {
  margin: auto 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  /* height: 100vh; */
  /* background-color: #0c244c; */
}

.container-side {
  display: flex;
  position: relative;
}

.container-side-enter-active {
  animation: slideInLeft 0.5s forwards;
}

.container-side-exit-active {
  animation: slideOutLeft 0.5s forwards;
}

.nav-link {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  cursor: pointer;
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  transition: all 0.3s ease-in;
}

.nav-link > .icon {
  font-size: 2.5rem;
  color: #00ffff;
  transition: all 0.3s ease-in;
}

.nav-link.active {
  color: #00ffff;
}
.nav-link.active > .icon {
  font-size: 3rem;
}

.nav-link:hover {
  color: #00ffff;
}
.logout-container {
  padding-top: 0.9rem;
  border-top: 1.5px solid #fff;
  /* border-bottom: 1.5px solid #fff; */
}

.close {
  display: none;
}

@media (max-width: 768px) {
  .container-side {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .side-bar-container {
    background-color: #0C234C;
    height: 100%;
    justify-content: center;
  }

  .close {
    position: absolute;
    top: 8px;
    left: 8px;
    width: fit-content;
    padding: 0;
    margin: 0;
    height: fit-content;
    background-color: transparent;
    cursor: pointer;
    display: block;
    color: #fff;
    transition: all 0.3s ease;
    font-size: 1.8rem;
  }

  .close:hover {
    color: #00ffff;
  }
}