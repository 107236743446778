.kyc_action {
    height: fit-content;
    width: fit-content;
    padding: 4px 8px;
    border-radius: 0;
    font-size: medium;
    cursor: pointer;
    margin-right: 8px;
}

.kyc_action:last-child {
    margin-right: 0;
}

.kyc_action.decline {
    background-color: red;
}