.navbar {
  display: flex;
  padding: 1.4rem 4rem;

  /* margin-bottom: 3rem; */
  justify-content: space-between;
  align-items: center;
  width: cover;
  background-color: #0c244c;
  color: aliceblue;
}
.side-bar-icon {
  /* margin-left: 5rem; */
  transition: all 1s;
}
.side-bar-icon:hover,
.nav-icon:hover {
  cursor: pointer;
  color: "#00ffff";
}
.main-link {
  text-decoration: none;
  color: #fff;
}
.main-nav-container {
  position: sticky;
  top: 0;
}
