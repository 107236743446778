* {
  padding: 0;
  margin: 0;
}
.navbar-main_container {
  background-color: #0c244c;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
}
.navbar-container {
  animation: backInRight 1s forwards;
  padding: 2rem 2rem;
  background-color: #000033;
  display: flex;
  color: #00ffff;
  justify-content: center;
  /* align-items: left; */
  flex-direction: column;
  gap: 2rem;
  border-radius: 40px;
}

.login-header {
  font-size: 1.7rem;
  font-weight: 600;
}

form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.forgot-password {
  font-weight: 600;
  cursor: pointer;
}

.input-fields {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
input {
  border: none;
  padding: 0 0.7rem;
  background-color: #0c244c;
  height: 2.6rem;
  font-weight: 600;
  color: #00ffff;
  font-size: 1.1rem;
  width: 25rem;
  border-radius: 10px;
  border: none;
  outline: none;
  border: 1px solid rgba(255, 255, 255, .5);
  transition: all 0.3s ease-in;
}

input:focus {
  border-color: #00ffff;
}
label {
  font-weight: 600;
  color: #00ffff;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
button {
  border: none;
  font-size: 1.2rem;
  background-color: #24afaf;
  padding: 0.6rem 3.5rem;
  border-radius: 15px;
  color: #fff;
  transition: all 0.3s;
  opacity: 0.9;
}
button:hover {
  opacity: 1;
}

button:disabled {
  pointer-events: none;
  opacity: 0.8;
}
@media (max-width: 530px) {
  input {
    padding: 0 0.7rem;
    background-color: #0c244c;
    height: 2.6rem;
    font-weight: 600;
    color: #00ffff;
    font-size: 1.1rem;
    width: 16rem;
    border-radius: 10px;
  }
}
