.bv-confirm-delete-user {
    background-color: white;
    font-size: 18px;
}

.bv-confirm-delete-user > header {
    padding: 16px;
    border-bottom: 1px solid #ccc;
}

.bv-confirm-delete-user > .body {
    padding: 32px 16px;
}

.bv-confirm-delete-user > .actions {
    width: 100%;
    padding: 16px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;
}

.bv-confirm-delete-user > .actions > button {
    width: fit-content;
    padding: 8px;
    background-color: #FF0000;
    border-radius: 4px;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16;
}

.bv-confirm-delete-user > .actions > button:disabled {
    opacity: .7;
    pointer-events: none;
}

.bv-confirm-delete-user > .actions > button:hover {
    opacity: 1;
}

.bv-confirm-delete-user > .actions > button:last-child {
    border: 1px solid #FF0000;
    background-color: transparent;
    color: #FF0000;
}