div.responsive-table {
  width: 100%;
  overflow: hidden;
  padding-bottom: 16px;
  overflow-x: auto;
}

div.responsive-table::-webkit-scrollbar {
  /* display: none; */
}

table {
  width: 100%;
  max-width: fit-content;
  margin: auto;
  border-collapse: collapse;
  background-color: #163467;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #1a3a71;
}

th {
  background-color: #f2f2f2;
}

td {
  color: #f2f2f2;
}

/* Responsive CSS */
/* @media screen and (max-width: 600px) {

  table,
  tr,
  td,
  th {
    display: block;
  }

  th {
    text-align: center;
  }

  td {
    border-bottom: none;
    position: relative;
  }

  td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
} */