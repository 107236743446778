.nav {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 3rem;
}
.main_routes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 4.5rem
}
.link {
  text-decoration: none;
  transition: all 0.3s ease-in;
}
.link:active {
  text-decoration: none;
}

.link.link-dashboard {
  color: #fff;
  font-weight: bold;
  font-size: larger;
}

.link.link-dashboard.link-active {
  color: #24afaf;
} 

@media (max-width: 768px) {
  .main_routes {
    padding: 2rem;
  }
}