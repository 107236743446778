.settings-container {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.link {
  text-decoration: none;
  transition: all 0.3s ease-in;
}

.link:active {
  text-decoration: none;
}

.link.link-settings {
  color: #fff;
  font-weight: bold;
  font-size: larger;
}

.link.link-settings.link-active {
  color: #24afaf;
}