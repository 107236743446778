.modal_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 400ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_container-enter {
  opacity: 0;
}

.modal_container-exit {
  opacity: 1;
}

.modal_container-exit-active {
  animation: fadeOut 0.5s forwards;
}

.modal_container-enter-active {
  animation: fadeIn 0.5s forwards;
}

.modal_card {
  z-index: 100000;
  background-color: transparent;
  border: 1px solid #ccc;
  outline: none;
  max-width: 500px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 10px;
  transition: all 400ms ease-in-out;
  animation: slideInDown 0.5s forwards;

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0.3rem;
}
