.bv-user-profit-log {}

.bv-user-profit-log h4 {
    font-size: larger;
    color: #fff;
}

.bv-user-profit-log > .headers {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 48px;
    flex-wrap: wrap;
}

.bv-user-profit-log button {
    cursor: pointer;
}