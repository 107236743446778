* {
  font-family: "Poppins";
  /* border: 1px solid black; */
  margin: 0;
  padding: 0;
}
Route:active {
  color: red;
}
a {
  color: #00ffffff;
}

.main-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100vh;
  width: 100%;
  position: relative;
}

.toggle-side-bar {
  height: 100vh;
  background-color: rgb(12, 36, 76);
}

.main_nav-bar {
  flex: 1;
  /* height: 100%; */
  overflow: hidden;
  overflow-y: auto;
  transition: all 0.5s ease;
  position: relative;
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.route {
  color: red;
}

@media (max-width: 500px) {
  h1 {
    font-size: 1rem;
  }
  .navbar {
    display: flex;
    padding: 0rem 4rem;
    /* margin-bottom: 3rem; */
    justify-content: space-between;
    align-items: center;
    width: cover;
    background-color: #0c244c;
    color: aliceblue;
  }
}
